<template>
  <div class="mt-4">
    <b-overlay variant="white" rounded="sm" :show="isBusy" :opacity="0.3">
      <b-card
        header="Edit Bulk Premium Offer by Vendor"
        header-bg-variant="primary"
        header-tag="h5"
        header-class="font-weight-bold"
      >
        <!-- filter -->
        <b-row>
          <!-- field vendor -->
          <b-col md="6" lg="4">
            <b-form-group label="Vendor Name" label-class="font-weight-bold">
              <multiselect
                v-model="vendor"
                label="name"
                track-by="name"
                placeholder="Enter Vendor"
                open-direction="bottom"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="vendorSearchOptions"
                :loading="isVendorSearch"
                @search-change="vendorFind"
                @input="isButtonSearchReady"
                required
              >
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- field type -->
          <b-col md="6" lg="4">
            <b-form-group label="Type" label-class="font-weight-bold">
              <div class="d-flex">
                <b-form-radio
                  v-model="actionRadio"
                  value="add"
                  @change="isButtonSearchReady"
                  required
                  >Add</b-form-radio
                >
                <b-form-radio v-model="actionRadio" value="remove"
                  >Remove</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Premium Offer" label-class="font-weight-bold">
          <!-- field premium offers -->
          <b-row>
            <b-col md="6" lg="4">
              <multiselect
                v-model="selectedPremiumOffers"
                group-values="offers"
                group-label="status"
                label="name"
                track-by="name"
                placeholder="Type to search"
                open-direction="bottom"
                @input="isButtonSearchReady"
                :searchable="true"
                :close-on-select="false"
                :multiple="true"
                :loading="isOffersSearch"
                :options="offersSearchOptions"
              >
                <template slot="option" slot-scope="props">
                  <div v-if="props.option.$isLabel">
                    <b-badge
                      variant="success"
                      v-if="props.option.$groupLabel == 'Active'"
                      >Active</b-badge
                    >
                    <b-badge variant="danger" v-else>InActive</b-badge>
                  </div>
                  <div v-else>
                    <span>{{ props.option.name }}</span>
                    <strong> [{{ props.option.id }}]</strong>
                  </div>
                </template>

                <template slot="tag" slot-scope="{ option, remove }">
                  <span
                    class="rounded mr-2 mb-1 p-1 px-2"
                    style="
                      display: inline-block;
                      background: #e5eff7;
                      color: #0060ae;
                    "
                  >
                    <span class="mr-1">{{ option.name }}</span>
                    <b-icon
                      icon="x-circle-fill"
                      variant="primary"
                      class="btn p-0 m-0"
                      @click="remove(option)"
                    ></b-icon>
                  </span>
                </template>
              </multiselect>
            </b-col>
            <b-col class="pl-0 ml-0">
              <b-button
                variant="primary"
                style="height: 43px"
                @click="fetchData"
                :disabled="isButtonSearchDisable"
              >
                Search Data
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
        <!-- filter end -->

        <!-- search field -->
        <b-row>
          <b-col>
            <div>
              <strong>Has Selected {{ itemsSelected.length }} data</strong>
            </div>
            <div>
              <small class="text-danger">
                * Maximum selected {{ maxSelectedRows }} data
              </small>
            </div>
          </b-col>
          <b-col md="6" lg="4">
            <b-input-group id="search-brand-group">
              <b-input-group-prepend is-text>
                <em class="fa fa-search"></em>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchBrandName"
                placeholder="Search Brand Name"
                type="search"
                id="search-brand"
                debounce="500"
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <!-- search field end -->

        <!-- table -->
        <div class="pt-3">
          <b-table
            class="border"
            id="table-brands-premium"
            sticky-header="500px"
            head-variant="light"
            show-empty
            hover
            :busy="isBusy"
            :items="items"
            :fields="fields"
          >
            <!-- style="min-height: 500px" -->
            <template #head(selected)="">
              <b-form-checkbox
                class="text-nowrap text-right head-table-selected"
                style="display: table-cell"
                v-model="selectAll"
                @change="onSelectAll"
              >
              </b-form-checkbox>
            </template>
            <template #cell(selected)="row">
              <b-form-checkbox
                class="text-nowrap text-right"
                style="display: table-cell"
                v-model="row.item.selected"
                @change="onSelectRow($event, row.item)"
              >
              </b-form-checkbox>
            </template>
            <template #cell(no)="row">
              {{ row.index + 1 + (perPage * currentPage - perPage) }}
            </template>
            <template #cell(offer_premium)="row">
              <span
                class="px-1"
                v-for="offerId in row.item.offer_premium"
                :key="offerId"
              >
                <b-badge variant="info">{{
                  findOfferNameById(offerId)
                }}</b-badge>
              </span>
            </template>
            <template #empty="">
              <div class="d-flex justify-content-center">
                <span class="m-auto text-center">
                  <div>
                    <img src="../../assets/img/product-not-found.png" alt="" />
                  </div>
                  <strong>Sorry data is not available</strong>
                </span>
              </div>
            </template>
          </b-table>
        </div>
        <!-- table end -->

        <!-- pagination -->
        <b-row>
          <b-col>
            <span
              >Record
              {{ totalRows > 0 ? perPage * currentPage - (perPage - 1) : 0 }} -
              {{
                perPage * currentPage > totalRows
                  ? totalRows
                  : perPage * currentPage
              }}
              of {{ totalRows }}
            </span>
          </b-col>
          <b-col class="d-flex">
            <b-pagination
              class="ml-auto"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </b-col>
        </b-row>
        <!-- pagination end -->

        <!-- Button Save -->
        <b-row>
          <b-col class="text-right">
            <b-button variant="primary" @click="onSubmit">Save</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';
export default {
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      maxSelectedRows: 300,
      vendor: undefined,
      actionRadio: undefined,
      selectedPremiumOffers: undefined,
      searchBrandName: '',
      stateFilterBefore: {
        actionRadio: undefined,
        vendor: undefined,
        selectedPremiumOffers: undefined,
      },
      selectAll: false,
      forSale: constant.OFFER_STATUS.READY_FOR_SALE,
      isVendorSearch: false,
      isOffersSearch: false,
      isButtonSearchDisable: true,
      offersSearchOptions: [],
      offersPremiumList: [],
      fields: [
        {
          key: 'selected',
          label: '',
          thStyle: {
            width: '10px',
          },
        },
        {
          key: 'no',
          thStyle: {
            width: '10px',
          },
        },
        {
          key: 'brand_name',
        },
        {
          key: 'offer_premium',
          label: 'Offer Name',
        },
      ],
      // items: [],
      itemsSelected: [],
    };
  },

  watch: {
    currentPage() {
      this.fetchData();
    },
    searchBrandName() {
      this.fetchData();
    },
    isSuccessBrandPremium(val) {
      if (!val) return;
      this.$swal({
        icon: 'success',
        toast: 'true',
        position: 'top-end',
        title: this.successMsgBrandPremium ?? 'Success!',
        showConfirmButton: true,
      });
      this.fetchData();
      this.itemsSelected = [];
    },
    isErrorBrandPremium(val) {
      if (!val) return;
      const defaultMessageError = 'System Error: An unexpected error has occurred. Please contact our support team for assistance.';
      this.$swal({
        icon: 'error',
        toast: 'true',
        position: 'top-end',
        title: this.errorMsgBrandPremium ? this.errorMsgBrandPremium : defaultMessageError,
        showConfirmButton: true,
      });
      this.itemsSelected = [];
    },
    stateFilterBefore: {
      handler() {
        this.itemsSelected = [];
        this.searchBrandName = '';
      },
      deep: true,
    },
  },

  created() {
    this.setOptionPremiumOffers();
  },

  computed: {
    ...mapState({
      vendorSearchOptions: (state) => state.vendors.items,
      items: (state) =>
        state.brandPremiumOffers.items?.map((val) => {
          return {
            selected: false,
            ...val,
          };
        }),
      totalRows: (state) => state.brandPremiumOffers.totalRows,
      isBusy: (state) => state.brandPremiumOffers.isLoading,
      isErrorBrandPremium: (state) => state.brandPremiumOffers.isError,
      isSuccessBrandPremium: (state) => state.brandPremiumOffers.isSuccess,
      successMsgBrandPremium: (state) =>
        state.brandPremiumOffers.successMessage,
      errorMsgBrandPremium: (state) => state.brandPremiumOffers.errorMessage,
    }),
  },

  methods: {
    ...mapActions('vendors', ['searchVendors']),
    ...mapActions('offerspremium', {
      searchPremiumOffers: 'searchOffers',
    }),
    ...mapActions('brandPremiumOffers', [
      'fetchBrandsWithPremiumOffers',
      'postBrandsWithPremiumOffers',
      'deleteBrandsWithPremiumOffers',
    ]),

    async onSubmit() {
      if (!this.isFilterReady()) return;
      if (!this.itemsSelected?.length) {
        this.$store.commit(`brandPremiumOffers/setIsError`, true);

        this.$store.commit(
          `brandPremiumOffers/setErrorMessage`,
          'No item selected'
        );
        return;
      }

      if (
        this.actionRadio == 'add' &&
        (await this.confirmAlertAdd().then((v) => v.isConfirmed))
      ) {
        this.postBrandsWithPremiumOffers({
          brands_id: this.itemsSelected.map((i) => i.brand_id),
          offers_id: this.selectedPremiumOffers.map((o) => o.id),
        });
      }
      if (
        this.actionRadio == 'remove' &&
        (await this.confirmAlertRemove().then((v) => v.isConfirmed))
      ) {
        this.deleteBrandsWithPremiumOffers({
          brands_id: this.itemsSelected.map((i) => i.brand_id),
          offers_id: this.selectedPremiumOffers.map((o) => o.id),
        });
      }
    },

    onSelectAll(value) {
      if (value == true) {
        if (this.onCheckMaxRow(this.items)) {
          this.selectAll = false;
          return;
        }
        this.removeSelectedRow(this.items);

        const items = this.items?.map((val) => ({
          ...val,
          selected: value,
        }));
        this.$store.commit(`brandPremiumOffers/setItems`, items);

        this.insertSelectedRow(items);
      } else if (value == false) {
        this.removeSelectedRow(this.items);
      }
    },

    onSelectRow(value, item) {
      if (value == true) {
        if (this.onCheckMaxRow([item])) {
          item.selected = false;
          return;
        }
        this.insertSelectedRow([item]);
      } else if (value == false) {
        this.removeSelectedRow([item]);
      }
      this.isRowSelectedAll();
    },

    onCheckMaxRow(additionalItem = []) {
      const additionalTotalRows = additionalItem.length;
      const totalCurrentSelect = this.itemsSelected.length;
      const isAvailable =
        additionalTotalRows + totalCurrentSelect > this.maxSelectedRows;
      if (isAvailable) {
        this.$swal(
          'Warning !',
          `Sorry, the data exceeds the maximum ${this.maxSelectedRows}, please reduce the data`,
          'warning'
        );
      }
      return isAvailable;
    },

    vendorFind(value) {
      if (!value) return;
      clearTimeout(this.debounce);

      this.isVendorSearch = true;
      this.debounce = setTimeout(() => {
        this.searchVendors({ name: value })
          .then(() => {
            this.isVendorSearch = false;
          })
          .catch(() => {
            this.isVendorSearch = false;
          });
      }, 600);
    },

    fetchData() {
      if (!this.isFilterReady()) {
        return;
      }
      this.stateFilterBefore.actionRadio = this.actionRadio;
      this.stateFilterBefore.vendor = this.vendor;
      this.stateFilterBefore.selectedPremiumOffers = this.selectedPremiumOffers;

      this.fetchBrandsWithPremiumOffers({
        vendor_id: this.vendor?.id,
        offers_id: this.selectedPremiumOffers?.map((p) => p.id),
        action_type: this.actionRadio,
        brand_name: this.searchBrandName,
        limit: this.perPage,
        offset: this.currentPage * this.perPage - this.perPage,
      }).then(() => {
        this.setCheckboxBySelectedItem();
      });
    },

    setOptionPremiumOffers() {
      this.searchPremiumOffers({ name: '' }).then((response) => {
        this.offersPremiumList = response.data?.data?.rows;
        this.offersSearchOptions = [];
        this.offersSearchOptions.push({
          status: 'Active',
          offers: response.data.data.rows?.filter(
            (val) => val.is_active == true && val.offer_status == this.forSale
          ),
        });
        this.offersSearchOptions.push({
          status: 'InActive',
          offers: response.data.data.rows?.filter(
            (val) => val.is_active == false || val.offer_status != this.forSale
          ),
        });
      });
    },

    setCheckboxBySelectedItem() {
      const itemsIdSelected = this.itemsSelected.map((val) => val.brand_id);
      const items = this.items.map((val) => {
        if (itemsIdSelected.includes(val.brand_id)) {
          val.selected = true;
        } else {
          val.selected = false;
        }
        return val;
      });
      this.$store.commit(`brandPremiumOffers/setItems`, items);

      this.isRowSelectedAll();
    },

    nameOfferWithId({ name, id }) {
      return `${name} - [${id}]`;
    },

    findOfferNameById(offerId) {
      if (!offerId) return;

      const offer = this.offersPremiumList.find((val) => val.id == offerId);
      return offer?.name;
    },

    insertSelectedRow(items = []) {
      this.itemsSelected.push(...items);
      this.setCheckboxBySelectedItem();
    },

    removeSelectedRow(items = []) {
      const itemsId = items.map((val) => val.brand_id);
      this.itemsSelected = this.itemsSelected.filter(
        (val) => !itemsId.includes(val.brand_id)
      );
      this.setCheckboxBySelectedItem();
    },

    isFilterReady() {
      if (!this.vendor?.id) {
        this.$swal('Vendor!!', 'Vendor must be filled out', 'warning');
        return false;
      }
      if (!this.actionRadio) {
        this.$swal('Type!!', 'Type must be filled out', 'warning');
        return false;
      }
      if (!this.selectedPremiumOffers?.length) {
        this.$swal(
          'Premium Offers!!',
          'Premium Offers must be filled out',
          'warning'
        );
        return false;
      }
      return true;
    },

    isButtonSearchReady() {
      if (
        !this.vendor?.id ||
        !this.actionRadio ||
        !this.selectedPremiumOffers?.length
      ) {
        this.isButtonSearchDisable = true;
      } else {
        this.isButtonSearchDisable = false;
      }
    },

    isRowSelectedAll() {
      const rows = this.items.filter((i) => i.selected == false);
      if (!rows?.length && !!this.items?.length) this.selectAll = true;
      else this.selectAll = false;
    },

    async confirmAlertAdd() {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary mx-1',
          cancelButton: 'btn btn-light mx-1',
        },
        buttonsStyling: false,
      });
      const result = await swalWithBootstrapButtons.fire({
        title: 'Are you sure to Add this data?',
        text: 'this action is quite dangerous, do you still want to continue?',
        icon: 'warning',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        reverseButtons: true,
      });

      return result;
    },

    async confirmAlertRemove() {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger mx-1',
          cancelButton: 'btn btn-light mx-1',
        },
        buttonsStyling: false,
      });
      const result = await swalWithBootstrapButtons.fire({
        title: 'Are you sure to Add this data?',
        text: 'this action is quite dangerous, do you still want to continue?',
        icon: 'warning',
        confirmButtonText: 'Remove',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        reverseButtons: true,
      });

      return result;
    },
  },
};
</script>

<style scoped>
#search-brand:focus {
  box-shadow: unset;
  border-color: #ced4da;
}
#search-brand {
  border: unset;
  background-color: unset;
  padding: unset;
  padding-right: 20px;
}
#search-brand::placeholder {
  color: #c6c9cc;
}
#search-brand-group .input-group-text {
  border: unset;
  background-color: unset;
  color: #71797f;
}
#search-brand-group {
  border-radius: 50rem !important;
  border: solid #c6c9cc 1px;
}
#table-brands-premium th {
  background-color: #f2f2f2;
  font-weight: bold;
}
</style>